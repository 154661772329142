.dumpster-container {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card.dumpster-card {
    width: 90%;
    margin: 5px 0;
    border-radius: 5px;
}
