
.nav-wrapper {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 999;
    width: 300px;
    display: flex;
    flex-direction: column;

    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.menu-container {
    position: absolute;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 20px;

    width: 100%;
    background: white;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.095);

    z-index: 99;
}

.menu-container:first-child {
    cursor: pointer;
}

.menu-container .div-line {
    border-left: 1px solid #eee;
    height: 20px;
    margin: 0 10px;
}
.menu-container .search-form {
    width: 70%;
    margin-right: 10px;
}

.menu-container .keyword-input {
    width: 100%;
    font-size: 15px;
    outline: none;
    box-sizing: border-box;
    border: 1px solid #ddd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    border-radius: 5px;

    height: 20px;
    padding: 0 10px;
}

.keyword-input:focus {
    border-color: #51a7e8;
}

#content-wrapper {
    width: 100%;
    background: white;
    min-height: 50px;
    max-height: 400px;

    padding-top: 30px;
    padding-bottom: 10px;
    overflow-y: auto;
    margin-top: 15px;
    transition: all 0.3s;
}

#content-wrapper#content-wrapper-disabled {
    visibility: hidden;
    transition: all 2s;
}

.content-fold {
    width: 100%;

    font-size: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    background: white;
}
.content-fold.folded {
    position: absolute;
    top: 35px;
}

.content-fold > * {
    cursor: pointer;
}


.reset-button:hover {
    animation: animate 1s linear infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media screen and (max-width: 500px) {
    .contact-form-card-body {
        flex-direction: column;
    }
    .contact-form-card-content:first-child {
        margin-bottom: 8px;
    }
}




