.day-info-container {
    width: 100%;
    padding-left: 10px;

    display: grid;
    grid-template-columns: repeat(4, minmax(100px, auto));
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: dense;

    align-items: center;
    justify-items: center;
}

@media screen and (min-width: 1500px) and (max-width: 1870px) {
    .day-info-container {
        grid-template-columns: repeat(3, minmax(100px, auto));
        grid-template-rows: repeat(3, minmax(100px, auto));
        grid-gap: 2px;
        align-items: center;
        justify-items: center;
    }

    .day-info-container > .g {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
    .day-info-container {
        grid-template-columns: repeat(2, minmax(100px, auto));
        grid-template-rows: repeat(4, minmax(100px, auto));
        grid-gap: 2px;

        align-items: center;
        justify-items: center;
    }
}

@media screen and (max-width: 400px) {
    .day-info-container {
        grid-template-columns: repeat(3, minmax(50px, auto));
        grid-template-rows: repeat(3, minmax(50px, auto));
        grid-gap: 2px;

        align-items: center;
        justify-items: center;
    }

    .day-info-container > .g {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
    }
}
